<template>
	<div class="TaskDetails bg-white" style="height: 100%">
		<i-form :model="task" :label-width="96" :rules="ruleVale">
			<FormItem label="任务名称" style="width: 40rem;">
				<i-input size="large" v-model="task.name"></i-input>
			</FormItem>
			<Row style="width: 40rem;">
				<i-col span="12">
					<FormItem label="开始日期" prop="startDate" style="text-align: left;">
						<DatePicker size="large" type="date" placeholder="选择日期" format="yyyy-MM-dd" v-model="task.startDate" style="width: 14rem;"></DatePicker>
					</FormItem>
				</i-col>
				<i-col span="12">
					<FormItem label="结束日期" prop="startDate" style="text-align: left;">
						<DatePicker size="large" type="date" placeholder="选择日期" format="yyyy-MM-dd" v-model="task.endDate" style="width: 14rem;"></DatePicker>
					</FormItem>
				</i-col>
				<i-col span="12">
					<FormItem label="计划数">
						<i-input size="large" disabled v-model="task.planNum"></i-input>
					</FormItem>
				</i-col>
				<i-col span="12">
					<FormItem label="对象性质">
						<i-select size="large" :disabled="isEdite" v-model="task.character" @on-change="changeCharacter">
							<Option value="个体">个体</Option>
							<Option value="团体">团体</Option>
						</i-select>
					</FormItem>
				</i-col>
			</Row>
			<FormItem label="任务对象" style="width: 40rem; position: relative;">
				<!-- 已选团体对象 -->
				<i-input ref="targetInput" v-show="isOrgan" v-model="selectOrganValue" size="large" type="textarea" :autosize="{minRows:3}" placeholder="单击列表框设置对象" @on-focus="addTarget('团体')"></i-input>
				<!-- 已选个体对象 -->
				<Table ref="selectIndiTargetTable" v-show="isIndi" border stripe size="small" :columns="selectUsersColums" :data="selectUsers">
					<div slot-scope="{ row }" slot="action">
						<Button icon="md-trash" size="small" @click="removeindiTarget(row)"></Button>
					</div>
				</Table>
				<Page v-show="isIndi" :total="selectUsersTotal" :page-size="20" size="small" show-total class="align-right marginBottom__2" />
				<Button v-show="isIndi" icon="md-add" size="small" class="addButton" @click="addTarget('个体')"></Button>
			</FormItem>
			<FormItem label="完成情况" style="width: 40rem; position: relative;">
				<!-- 量表具体完成情况 -->
				<Table ref="completeListTable" size="small" border stripe :columns="completeListColumns" :data="completeListScale">
					<div slot-scope="{ row }" slot="notUsers">
						<a @click="getNotCompletelist(row)">名单</a>
					</div>
					<div slot-scope="{ row }" slot="action">
						<Button icon="md-trash" size="small" @click="removeScale(row)"></Button>
					</div>
				</Table>
				<Button icon="md-add" size="small" class="addButton" @click="openAddScaleModal()"></Button>
			</FormItem>
			<div style="width: 40rem;">
				<Button size="large" type="primary" class="wideButton mb-1">提 交</Button>
			</div>
		</i-form>
		<!-- 团体对象设置对话框 -->
		<Modal v-model="organTargetModal" scrollable title="团体对象" ok-text="提交" cancel-text="取消" width="600">
			<i-select :disabled="isEdite" size="large" filterable v-model="task.unitId">
				<Option v-for="(item,key) in organOption" :key="key" :value="item.unitId">{{item.unitName}}</Option>
			</i-select>
			<Tree ref="targetTree" show-checkbox multiple expand-node :data="departTree" height="436" class="targetTree mt-1 pl-1"></Tree>
		</Modal>
		<!-- 个体对象设置对话框 -->
		<Modal v-model="indiTargetModal" scrollable title="选择个体对象" footer-hide width="600">
			<i-input v-model="indiName" style="width: 18.5rem" class="el-inline mb-1 mr-1">
				<Button slot="prepend">姓名</Button>
				<Button slot="append" icon="md-search" style="width: 2.5rem"></Button>
			</i-input>
			<Table ref="usersTable" border stripe size="small" :columns="allUsersColums" :data="allUsers" height="436">
				<div slot-scope="{ row }" slot="action">
					<Button icon="md-add" size="small" @click="addIndiTarget(row)"></Button>
				</div>
			</Table>
			<Page :total="usersTotal" :page-size="20" size="small" show-total class="pt-1 align-right" />
		</Modal>
		<!-- 未完成人员名单对话框 -->
		<Modal v-model="notCompleteModal" scrollable :title="notCompleteclaleName+'未完成名单'" footer-hide width="600">
			<Table ref="notCompleteTable" border stripe size="small" :columns="notCompleteUsersColums" :data="notCompleteUsers" height="436">
			</Table>
			<Row class="mt-1">
				<i-col span="4">
					<Button size="small">导出名单</Button>
				</i-col>
				<i-col span="20">
					<Page :total="notCompleteusersTotal" :page-size="20" size="small" show-total  class="align-right" />
				</i-col>
			</Row>
		</Modal>
		<!-- 添加量表对话框 -->
		<Modal v-model="addScaleModal" scrollable title="选择量表" footer-hide width="600">
			<i-input v-model="scaleValue" style="width: 18.5rem" class="el-inline mb-1">
				<Button slot="prepend">量表名称</Button>
				<Button slot="append" icon="md-search" style="width: 2.5rem"></Button>
			</i-input>
			<Table ref="scaleTable" border stripe size="small" :columns="allScaleColums" :data="allScale" height="436">
				<div slot-scope="{ row }" slot="action">
					<Button icon="md-add" size="small" @click="addScale(row)"></Button>
				</div>
			</Table>
			<Page :total="scalesTotal" :page-size="20" size="small" show-total class="pt-1 align-right" />
		</Modal>
	</div>
</template>

<script>
	import organOption from '../json/organ_tData.json'
	import departTree from '../json/depart_trData.json'
	export default {
		name: 'TaskDetails',
		data() {
			return {
				// 主要信息
				task: {},
				ruleVale: {
					name: [{
						required: true,
						message: '名称不能为空',
						trigger: 'blur'
					}],
					startDate: [{
						type: 'date',
						message: '请选择日期',
						trigger: 'change'
					}]
				},
				isEdite: false, //编辑还是添加
				
				// 已选团体对象
				isOrgan:false,
				selectOrganValue:'',
				
				// 已选个体对象
				isIndi:true,
				selectUsersColums:[{
					type:'index',
					title:'序号',
					width:'45'
				},{
					title:'用户号',
					key:'userId'
				}, {
					title:'姓名',
					key:'userName'
				}, {
					title:'性别',
					key:'gender',
					render:(h,params) =>{
						return h ('span',this.myjs.getGender(params.row.gender));
					}
				}, {
					title:'年龄',
					key:'age',
					render: (h, params) => {
						let age = this.myjs.getAge(params.row.birthday,new Date());
						return h('span', age)
					}
				}, {
					title: '移除',
					slot: 'action',
					width: 46
				}],
				selectUsers:[],
				selectUsersTotal:10,
				
				// 量表完成情况
				completeListColumns: [{
					type:'index',
					title:'序号',
					width:'45'
				},{
					title: '量表',
					key: 'label',
					minWidth: 300
				}, {
					title: '完成数',
					key: 'completeSum',
					minWidth: 60
				}, {
					title: '未完成',
					slot: 'notUsers',
					width: 60
				}, {
					title: '移除',
					slot: 'action',
					width: 46
				}],
				completeListScale:[{
					label:'儿童自我意识量表',
					completeSum:0
					
				}],
				allUsers:[{
					userId:10001,
					userName:'于文章',
					gender:1,
					birthday:'1984-11-25'
				}],
				
				// 团体对象设置对话框
				organTargetModal: false,
				organOption,
				departTree,
				
				// 个体对象设置对话框
				indiTargetModal:false,
				indiName:'',
				allUsersColums:[{
					type:'index',
					title:'序号',
					width:'45'
				},{
					title:'用户号',
					key:'userId'
				}, {
					title:'姓名',
					key:'userName'
				}, {
					title:'性别',
					key:'gender',
					render:(h,params) =>{
						return h ('span',this.myjs.getGender(params.row.gender));
					}
				}, {
					title:'年龄',
					key:'age',
					render: (h, params) => {
						let age = this.myjs.getAge(params.row.birthday,new Date());
						return h('span', age)
					}
				}, {
					title: '添加',
					slot: 'action',
					width: 46
				}],
				usersTotal:2000,
				
				// 未完成人员名单对话框
				notCompleteModal:false,
				notCompleteclaleName:'',
				notCompleteUsersColums:[{
					type:'index',
					title:'序号',
					width:'45'
				},{
					title:'用户号',
					key:'userId'
				}, {
					title:'姓名',
					key:'userName'
				}, {
					title:'性别',
					key:'gender',
					render:(h,params) =>{
						return h ('span',this.myjs.getGender(params.row.gender));
					}
				}, {
					title:'年龄',
					key:'age',
					render: (h, params) => {
						let age = this.myjs.getAge(params.row.birthday,new Date());
						return h('span', age)
					}
				}],
				notCompleteUsers:[],
				notCompleteusersTotal:3,
				
				// 添加量表对话框
				addScaleModal:false,
				scaleValue:'',
				allScaleColums:[{
					type:'index',
					title:'序号',
					width:'45'
				},{
					title:'量表',
					key:'label'
				},{
					title: '添加',
					slot: 'action',
					width: 46
				}],
				allScale:[],
				scalesTotal:200
			}
		},
		created() {
			// 判断是编辑还是新建
			this._initialization();
			
		},
		mounted() {
			
		},
		updated() {
			
		},
		methods: {
			// 设置面包屑
			crumbstring(str, len) {
				let setValue = {
					lenCtrl: len,
					addValue: str
				};
				this.$store.dispatch('setBreadcrumb', setValue);
			},
			// 初始化
			_initialization() {
				let a = JSON.parse(sessionStorage.getItem('pageStr'));
				// 判断编辑还是新建
				if (a.taskId) {
					this.task = a;
					this.crumbstring(["任务详情",this.task.name],2);
					this.isEdite = true;
					if(this.task.character == "团体"){
						this.isOrgan = true;
						this.isIndi = false;
						//团体任务初始化，根据部门树和发布对象，选中节点
						this.setCheckNodes(this.departTree);	
						//根据发布对象和部门结构生成对象列表框的内容
						this.setTargetInputValue(this.departTree,this.task.scaleTarget);
					}else{
						this.isOrgan = false;
						this.isIndi = true
					}
				} else {
					this.crumbstring(["新建任务"], 2);
					this.isEdite = false;
					this.task.character = "个体";
					this.departTree = []
				}
			},
			// 打开对象选择对话框
			addTarget(character) {
				if (character == "团体") {
					this.$refs.targetInput.blur();
					this.organTargetModal = true
				}else{
					this.indiTargetModal =true
				}
			},
			//新建任务时，选择任务性质时改变任务对象元素
			changeCharacter(value){
				switch (value){
					case "团体":
						this.isOrgan = true;
						this.isIndi = false;
						break;
					default:
						this.isOrgan = false;
						this.isIndi = true;
				}
			},
						
			// 根据发布对象的id设置选中节点（团体）
			setCheckNodes(tree) {
				for (let i = 0; i < tree.length; i++) {
					if (this.task.scaleTarget.includes(tree[i].departId)) {
						tree[i].["checked"] = true;
					}else if(tree[i].children){
						this.setCheckNodes(tree[i].children) //递归子节点
					}
				}
			},
			
			// 根据目标对象和部门结构生成对象列表框的内容（团体）
			setTargetInputValue(tree,scaleTarget,parentTitle,list){
				if(!parentTitle){
					parentTitle = new Array()
				}
				if(!list){
					list = new Array()
				}
				for (let i = 0; i < tree.length; i++) {
					if(scaleTarget.includes(tree[i].departId)){
						parentTitle.push(tree[i].title);
						let a = parentTitle.join("/");
						list.push(a);
						if(list.length === scaleTarget.length){
							this.targetInputValue = list.join("\n")
						}
						parentTitle.pop();
						if(i == tree.length - 1){
							parentTitle.pop();
						}
					}else if(tree[i].children){
						parentTitle.push(tree[i].title);
						this.setTargetInputValue(tree[i].children, scaleTarget, parentTitle,list);
					}	
				}
			},
			// 移除个体对象
			removeindiTarget(row){
				console.log(row)
			},
			// 添加个人对象
			addIndiTarget(row){
				console.log(row)
			},
			// 移除量表
			removeScale(row){
				console.log(row)
			},
			// 打开个体对象对话框
			getNotCompletelist(row){
				this.notCompleteclaleName = row.label;
				this.notCompleteModal = true
			},
			// 打开量表设置对话框
			openAddScaleModal(){
				this.addScaleModal = true
			},
			//添加量表
			addScale(row){
				console.log(row)
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.targetTree {
		height: 25rem;
		border: 1px solid #dcdee2;
		overflow-y: auto;
	}
	.addButton{
		position: absolute;
		top:2rem;
		left: -2.2rem;
		text-align: center;
	}
	.myModalFooter{
		margin-top: 0.5rem;
		text-align: right;
	}
	.marginBottom__2{
		margin-bottom: -1rem;
	}
</style>
